import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AllLayouts from '../layouts/allLayouts';
import { Hero } from '../components/hero/Hero';

type PageType = {
  wpPage: {
    title: string,
    acfHeader: {
      metaDescription: string,
      metaTitle: string,
      metaImage: {
        sourceUrl: string
      }
      hero: {
        heading: string
        subHeading: string
      }
    }
    acfComponents: {
      components?: []
    }

  }
}

const Page = ({ data }: { data: PageType}) => {
  const { wpPage } = data;
  const {
    hero, metaImage, metaTitle, metaDescription,
  } = wpPage.acfHeader;
  const layouts = wpPage.acfComponents.components;

  return (
    <>
      {metaTitle && (
        <Helmet>
          {/* <!-- Primary Meta Tags --> */}
          <title>{metaTitle}</title>
          <meta name="title" content={metaTitle} />
          <meta name="description" content={metaDescription} />

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content={metaImage.sourceUrl} />

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={window.location.href} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:description" content={metaDescription} />
          <meta property="twitter:image" content={metaImage.sourceUrl} />
        </Helmet>
      )}
      {/* @ts-ignore */}
      <Hero hero={hero} />

      {/* eslint-disable-next-line react/no-array-index-key */}
      { layouts?.map((layout : any, index : number) => <AllLayouts key={index} component={layout} />) }
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      databaseId
      acfHeader {
        metaDescription
        metaTitle
        metaImage {
          sourceUrl
        }
        hero {
          heading
          button {
            title
            target
            url
          }
          layout
          subHeading
          description
          image {
            altText
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 2600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          video {
            mediaItemUrl
          }
          poster {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      acfComponents {
        components {
          ... on WpPage_Acfcomponents_Components_Reviews {
            fieldGroupName
            limit
            title
          }
          ... on WpPage_Acfcomponents_Components_Banner {
            fieldGroupName
            title
            layout
            column1Obj {
              price
              subtitle
              info
              button {
                target
                title
                url
              }
              secondButton {
                target
                title
                url
              }
              imageThumb {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              image {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              title
            }
            column2Obj {
              price
              subtitle
              info
              button {
                target
                title
                url
              }
              image {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageThumb {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              title
            }
            column3Obj {
              price
              subtitle
              info
              button {
                target
                title
                url
              }
              image {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageThumb {
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              title
            }
          }
          ... on WpPage_Acfcomponents_Components_Cta {
            fieldGroupName
            column1
            column2
            title
            button {
              title
              url
              target
            }
          }
          ... on WpPage_Acfcomponents_Components_Partners {
            fieldGroupName
            title
            partner {
              ... on WpPage_Acfcomponents_Components_Partners_Partner_Item {
                description
                fieldGroupName
                title
                image {
                  altText
                  sourceUrl
                }
              }
            }
          }
          ... on WpPage_Acfcomponents_Components_Text {
            fieldGroupName
            heading
            column1
            column2
          }
          ... on WpPage_Acfcomponents_Components_Image {
            fieldGroupName
            image {
              altText
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WpPage_Acfcomponents_Components_TextImage {
            fieldGroupName
            position
            background
            heading
            body
            button {
              title
              url
            }
            image {
              altText
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WpPage_Acfcomponents_Components_Faq {
            fieldGroupName
            section {
              heading
              name
              text
              type
              faq {
                question
                answer
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
